import Enforce from "../login/index";
import connect from "../login/connect";

const LoginRoot = () => {
	var path = window.location.href;
	var postPath = path.substring(path.indexOf("=") + 1);
	if (postPath.includes("login")) {
		window.location.href = "/";
	} else {
		window.location.href = decodeURIComponent(postPath);
	}
	return "";
};

export default Enforce(connect(LoginRoot));
